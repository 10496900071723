import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import CreateUpdateDeleteMilestone from "./CreateUpdateDeleteMilestone";
import { getMilestone, updateMilestone } from "../../../services/roadmap/roadmap.service";
import InfiniteLoadingBar from "../components/InfiniteLoadingBar";
import SimpleHeader from "../../../components/Headers/SimpleHeader";
import { Card, Col, Container, Row } from "reactstrap";
import LoadingSpinnerBox from "../components/LoadingSpinnerBox";
import NotFoundCard from "../components/NotFoundCard";

function EditMilestone() {
  const { orgId, projectId, id } = useParams();
  const [milestone, setMilestone] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        const milestone = await getMilestone(orgId, projectId, id);
        setMilestone(milestone);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }

    fetchData();
  }, [id, orgId, projectId]);

  const handleSubmit = async (values) => {
    return await updateMilestone(orgId, projectId, id, values);
  };

  return (
    <>
      {loading && <InfiniteLoadingBar />}
      <SimpleHeader/>
      <Container className="mt--6" fluid>
        <Row>
          <Col>
            <div className="card-wrapper">
              {loading && <Card><LoadingSpinnerBox /></Card>}
              {milestone && <CreateUpdateDeleteMilestone onSubmit={handleSubmit} milestone={milestone} />}
              {!milestone && !loading && <NotFoundCard message={"Milestone not found"} />}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default EditMilestone;
